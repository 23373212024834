import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLFormElement> {
  static targets = ['output']

  declare outputTarget: HTMLTextAreaElement | HTMLInputElement

  async onInput (e: Event): Promise<void> {
    const input = e.target as HTMLInputElement

    if (input?.files !== null) {
      await this.outputFile(input.files[0])
    }
  }

  async onDrop (e: DragEvent): Promise<void> {
    e.preventDefault()

    const file = e.dataTransfer?.files[0]
    await this.outputFile(file)
  }

  prevent (e: Event): void {
    e.preventDefault()
  }

  private async outputFile (file: File | undefined | null): Promise<void> {
    if (file === undefined || file === null) {
      return
    }

    this.outputTarget.value = await file.text()
  }
}
