import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

export default class extends Controller<HTMLFormElement> {
  private readonly events: string[] = ['input', 'trix-change', 'turbo:submit-start']

  connect (): void {
    this.events.forEach(event => this.element.addEventListener(event, this))
  }

  disconnect (): void {
    this.events.forEach(event => this.element.removeEventListener(event, this))
  }

  handleEvent (event: Event): void {
    switch (event.type) {
      case 'input':
      case 'trix-change':
        this.sully()
        break
      case 'turbo:submit-start':
        if (this.isCleansable(event)) this.cleanse()
        break
    }
  }

  submit (): void {
    this.element.requestSubmit()
  }

  submitWithDebounce = debounce(this.submit, 300)

  clear (): void {
    const clearableInputs = this.element.querySelectorAll('input:not([data-clear="false"]), textarea:not([data-clear="false"])')
    clearableInputs.forEach(el => { (el as HTMLInputElement | HTMLTextAreaElement).value = '' })
  }

  resetIfSuccess (e: CustomEvent): void {
    if (e.detail.success === true) {
      this.element.reset()
    }
  }

  sully (): void {
    this.dirty = true
    this.submitButtons.forEach((button) => button.setAttribute('aria-disabled', 'false'))
  }

  cleanse (): void {
    this.dirty = false
    this.submitButtons.forEach((button) => button.setAttribute('aria-disabled', 'true'))
  }

  get dirty (): boolean {
    return this.data.get('dirty') === 'true'
  }

  private set dirty (value: boolean) {
    this.data.set('dirty', value.toString())
  }

  private get submitButtons (): Element[] {
    return Array.from(this.element.elements).filter((element: Element) => (
      element.getAttribute('type') === 'submit'
    ))
  }

  private isCleansable (event: Event): boolean {
    const button = (event as CustomEvent).detail.formSubmission.submitter as HTMLElement
    return button?.dataset?.nocleanse === undefined
  }
}
