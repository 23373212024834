import { Source, Response } from '.'

export default class implements Source {
  private readonly googleService

  constructor (autoCompleteSuggestion: any) {
    this.googleService = autoCompleteSuggestion
  }

  async fetch (query: string): Promise<Response> {
    if (query.length === 0) {
      return await Promise.resolve({ data: { results: [] } })
    }

    const { suggestions } = await this.googleService.fetchAutocompleteSuggestions({
      input: query,
      includedPrimaryTypes: [
        'locality',
        'administrative_area_level_1',
        'administrative_area_level_2'
      ]
    })

    const results = suggestions.map(async (suggestion) => {
      const place = suggestion.placePrediction?.toPlace()

      if (place === undefined) {
        return {}
      }

      await place.fetchFields({ fields: ['id', 'formattedAddress'] })
      console.log({
        id: place.id,
        value: this.serializePlace(place),
        text1: place.formattedAddress
      })

      return {
        id: place.id,
        value: this.serializePlace(place),
        text1: place.formattedAddress
      }
    })

    return await Promise.all(results).then(results => ({ data: { results } }))
  }

  private serializePlace (place: google.maps.places.Place): string {
    return [place.id, place.formattedAddress].join('::')
  }
}
